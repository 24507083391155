import {request, makeFormParams} from '../axios';

export const state = {};
export const mutations = {};
export const actions = {
  //  코스 리스트 가져오기
  getCourseList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/course/get_course_list', {
        params
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },

  // 관리자 코스 등록
  addCourse(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('course/add_coding_course', makeFormParams(params), {
        headers: {'Content-Type': 'multipart/form-data'}
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },

  // 코스 상세 조회
  /**
   *
   * @param {*} context
   * @param {*} params
   * @param {*} id:CourseId
   * @returns
   */
  getCourseDetail(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('course/get_course_info', {
        params
      });

      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },

  // 관리자 코스 삭제
  deleteCourse(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.delete('course/delete_coding_course', {
        params
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
  /**
   * 코스 그룹 가져오기
   * @param {*} context
   * @param {*} params (page, perPage)
   * @returns
   */
  course_group_list(context, params) {
    return new Promise(async (resolve, reject) => {
      console.log(params);
      const result = await request.get('course/course_group_list', {
        params
      });

      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  // 코스 그룹 등록
  courseGroupAdd(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('course/course_group_add', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  // 코스 그룹 단일 정보
  getCourseGroupInfo(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('course/course_group_info', {
        params
      });
      console.log(result);
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  deleteCourseGroup(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.delete('course/delete_course_group', {
        params
      });
      console.log(result);
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  }
};
